.fixed {
  width: 50%;
}

.frame2 {
  width: 100%;
}

.shadow {
  /* -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7)); */
  filter: drop-shadow(0px 0px 20px rgba(255, 0, 0, 0.7));
  /* Similar syntax to box-shadow */
}

.animation-canvas {
  height: 300vh;
  overflow: auto;
  position: relative;
}

.animation-canvas canvas {
  position: fixed;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home canvas {
  display: block;
}

body {
  overflow-x: hidden;
}


/* Promotion Page */

.promotion {
  position: relative;
  width: 100%;

  -webkit-filter: drop-shadow(0px 0px 10px black);
  filter: drop-shadow(0px 0px 10px black);

  --top-color: #EBB471;
  --bottom-color: #EBB471;

  background: -webkit-gradient(linear, left top, left bottom, from(var(--top-color)), to(var(--bottom-color)));
  background: -o-linear-gradient(top, var(--top-color), var(--bottom-color));
  background: linear-gradient(to bottom, var(--top-color), var(--bottom-color));

  >div {
    width: 100%;
    >div {
      position: relative;
      left: 50%;
      max-width: var(--content-width);
      transform: translateX(-50%);
    }
  }

  &.open {
    display: block;
    animation: fadeIn 1s ease-in-out;
  }

  &.hide {
    display: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.title {
  position: absolute;
  top: calc(-0.5 * var(--title-height));
  height: var(--title-height);

  & img {
    height: 100%;
  }
}


/* Poster Board Height */
.board {
  height: 900px;
}


/* Poster Positions */
.poster {
  position: absolute;
  height: auto;

  >img {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
  }

  &:nth-child(1) {
    width: 420px;
    top: 80px;
    left: 440px;
    transform: rotate(1deg) translate(0%);
  }

  &:nth-child(2) {
    width: 390px;
    top: 70px;
    left: 0px;
    transform: rotate(-4deg);
  }

  &:nth-child(3) {
    width: 410px;
    top: 90px;
    left: 900px;
    transform: rotate(2deg);
  }

  &:nth-child(4) {
    width: 300px;
    top: 75px;
    left: 960px;
    transform: rotate(5deg);
  }

  &:nth-child(5) {
    width: 300px;
    top: 520px;
    left: 320px;
    transform: rotate(-4deg);
  }

  &:nth-child(6) {
    width: 300px;
    top: 600px;
    left: 0px;
    transform: rotate(2deg);
  }

  &:nth-child(7) {
    width: 320px;
    top: 120px;
    right: 0px;
    transform: rotate(-0deg);
  }
}

/* ARCHIVE: do NOT delete */
/* .poster {
  position: absolute;
  height: auto;

  &:hover {
    cursor: pointer;
  }

  & .tape {
    position: absolute;
    width: 200px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & .poster-image {
    width: 100%;

    >img {
      width: 100%;
    }
  }
} */