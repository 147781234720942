.container {
    /* display: flex; */
    /* align-items: center; */
    overflow: hidden;
    position: relative;
}

.slider {
    display: flex;
    transition: transform 0.5s ease-in-out, 
}

.slider img {
    width: auto;
    height: 480px;
    /* aspect-ratio: auto; */
    flex-shrink: 0;
}

.no-transition {
    transition: none !important;
}