:root {
	--title-height: 200px;
	--content-width: 1280px;
}

.container>div {
	width: 100vw;
}

.padding {
	height: 120px;
	background-color: black;
}


/* General Wrapper */
.blockWrapper {
	height: 480px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;

	>div {
		width: var(--content-width);
		position: relative;
		/* background-color: rgba(227, 227, 227, 0.459); */
	}
}


/* General Title Image */
.title {
	position: absolute;
	/* z-index: 999; */
}


/* Shadow on titles */
.hour,
.address,
.contact {
	-webkit-filter: drop-shadow(0px 0px 10px black);
	filter: drop-shadow(0px 0px 10px black);
}


/* Block 1: HOUR */
.hour {
	--top-color: #323232;
	--bottom-color: #1A1A1A;

	background: -webkit-gradient(linear, left top, left bottom, from(var(--top-color)), to(var(--bottom-color)));
	background: -o-linear-gradient(top, var(--top-color), var(--bottom-color));
	background: linear-gradient(to bottom, var(--top-color), var(--bottom-color));

	>div {
		display: -ms-grid;
		display: grid;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
	}

	& .title {
		top: calc(-0.5*var(--title-height));

		>img {
			height: var(--title-height);
		}
	}
}

/* left column */
.hour>div>.gridItem:nth-child(2) {
	/* background: rgba(0, 0, 255, 0.3); */
	-ms-grid-column: 1;
	grid-column: 1;

	>div {
		color: white;

		display: -ms-grid;
		display: grid;

		-ms-grid-columns: auto 32px auto;
		grid-template-columns: auto auto;
		grid-auto-columns: -webkit-max-content;
		grid-auto-columns: max-content;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;

		-webkit-column-gap: 32px;
		-moz-column-gap: 32px;
		column-gap: 32px;

		>span:nth-child(1),
		>span:nth-child(3) {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;

			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;

			-ms-grid-column-align: right;
			justify-self: right;

			font-size: 16px;
			/* font-family: "Futura Bold"; */
			font-weight: 700;
		}

		>span:nth-child(2),
		>span:nth-child(4) {
			text-align: left;
			font-size: 32px;
		}

		>span:nth-child(4) {
			color: #DB4D6D;
		}
	}
}

/* right column */
.hour>div .gridItem:last-child {
	grid-column: 2;

	& .timer {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;

		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;

		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;

		>span:first-child {
			color: white;
			/* font-family: "SevenSegment Medium"; */
			/* font-family: "7Segment Medium"; */
			font-size: 128px;
			/* manually tuned value */
			line-height: 91px;
			/* SevenSegment: 91; Lcd: 87 */
		}

		>span:last-child {
			font-size: 32px;
			text-transform: lowercase;
		}

		& .open {
			color: #00AA90;
		}

		& .closed {
			color: #DB4D6D;
		}

		& .soon {
			color: #FFC408;
		}
	}
}


/* Block 2: ADDRESS */
.address {
	--top-color: #68B8DC;
	--bottom-color: #52A6CD;

	background: -webkit-gradient(linear, left top, left bottom, from(var(--top-color)), to(var(--bottom-color)));
	background: -o-linear-gradient(top, var(--top-color), var(--bottom-color));
	background: linear-gradient(to bottom, var(--top-color), var(--bottom-color));

	>div {
		display: -ms-grid;
		display: grid;

		-ms-grid-columns: 2fr 3fr;
		grid-template-columns: 2fr 3fr;
	}

	& .title {
		top: calc(-0.5*var(--title-height));

		>img {
			height: var(--title-height);
		}
	}
}

/* left-column */
.address>div .gridItem:nth-child(2) {
	/* background: rgba(255, 0, 0, 0.407); */

	>p {
		text-align: right;
		color: white;
		font-size: 32px;

		/* background-color: rgba(0, 0, 0, 0.371); */

		& a {
			color: inherit;
			text-decoration: none;

			&:hover {
				/* text-shadow: 5px 5px 20px rgba(255, 255, 255, 1); */
				text-decoration: underline;
			}
		}
	}
}

/* right-column */
.address>div .gridItem:last-child {
	>div {
		/* width: 80%;
		aspect-ratio: 3/1; */

		width: 100%;
		height: 100%;

		/* background: rgba(244, 255, 41, 0.407); */
	}
}


/* Block 3: CONTACT */
.contact {
	--top-color: #F39EB1;
	--bottom-color: #E48B9F;

	background: -webkit-gradient(linear, left top, left bottom, from(var(--top-color)), to(var(--bottom-color)));
	background: -o-linear-gradient(top, var(--top-color), var(--bottom-color));
	background: linear-gradient(to bottom, var(--top-color), var(--bottom-color));

	& .title {
		position: relative;

		/* transform: translate(-200px); */
		>img {
			height: var(--title-height);
			position: absolute;
		}

		top: calc(-0.5*var(--title-height));
	}
}

/* text */
.contact>div .gridItem {
	>p {
		text-align: center;
		color: black;
		font-size: 32px;

		& a {
			color: inherit;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}