.container {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 44px;

  /* background-color: rgba(128, 128, 128, 0.5); */
  background-color: rgba(64, 64, 64, 0.5);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* -webkit-backdrop-filter: blur(10px); */
  backdrop-filter: blur(10px);

  /* box-shadow: 1px 3px 5px 0 rgba(0, 0, 0, 0.5); */
  box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.5);
}

.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navBtn {
  margin: 0 3px;

  width: 36px;
  height: 25.34px;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  /* background-color: rgba(255, 255, 255, 0.842); */
  /* opacity: 0.8; */

  cursor: pointer;

  border-radius: 10px;
}

/* NOTE: useful application of drop-shadow, filter, dropback-filter */
.navBtn {
  filter: invert(1) drop-shadow(0 0 2px white);
}

.no-invert {
  filter: invert(0) drop-shadow(0 0 2px white);
}

.logo-off {
  /* filter: drop-shadow(0 0 2px #88B698); */
  filter: drop-shadow(0 0 2px white);
}

.logo-on {
  filter: drop-shadow(0 0 5px #88B698);
}


/* Search Bar */
.search {
  position: absolute;
  right: 50px;
}

.searchResult {
  width: 100%;
}


/* Language Dropdown */
.dropdownButton {
  position: absolute;
  display: inline-block;
  right: 0px;
}

.dropdownMenu {
  position: fixed;
  z-index: 999;

  /* relative position to the button */
  top: 64px;
  right: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  display: none;

  &.open {
    display: block;
    animation: fadeIn 0.5s ease-in-out;
  }

  /* Dropdown Menu Style */
  --border: 0px;
  --height: 33px;
  --radius: calc(var(--height) / 2);

  border: solid var(--border) white;
  border-radius: calc(var(--radius) + var(--border));

  color: white;
  background-color: rgba(64, 64, 64, 0.5);
  backdrop-filter: blur(10px);
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.5);

  & ul {
    list-style: none;
    margin: 0;
    padding: 0 0px;
    width: max-content;
  }

  & li {
    position: relative;
    cursor: pointer;

    font-size: 16px;

    width: calc(var(--height) * 4);
    height: var(--height);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Horizontal border */
  --rule-width: 1.5px;

  & li:before {
    content: '';
    /* This is necessary for the pseudo-element to be generated */
    position: absolute;
    top: calc(var(--rule-width) * -0.5);
    left: 50%;
    /* Adjust this to move the line to the desired start position */
    width: 75%;
    /* This controls the width of the line */
    border-top: calc(var(--rule-width)) solid white;
    /* Translate back to center */
    transform: translate(-50%);
  }

  /* Remove the line for the first item */
  & li:first-child:before {
    display: none;
  }

  & li:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }

  /* Rounded corners for the first li on hover */
  & li:first-child:hover,
  & li:last-child:hover {
    border-radius: var(--radius);
  }

  & li:first-child:hover {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  /* Rounded corners for the last li on hover */
  & li:last-child:hover {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* .dropdownMenu.open {
  display: block;
  animation: fadeIn 0.5s ease-in-out;
} */