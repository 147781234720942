/* SANS-SERIF */
.en-medium {
    font-family: "Jost", sans-serif;
    font-weight: 500;
}

.en-bold {
    font-family: "Jost", sans-serif;
    font-weight: 700;
}

.en-heavy {
    font-family: "Jost", sans-serif;
    font-weight: 900;
}

.fr-medium {
    /* font-family: ""; */
}

.fr-bold {
    /* font-family: ""; */
}

.sc-medium {
	font-family: "Noto Sans SC", sans-serif;
    font-weight: 500;
}

.sc-bold {
	font-family: "Noto Sans SC", sans-serif;
    font-weight: 700;
}

.sc-heavy {
	font-family: "Noto Sans SC", sans-serif;
    font-weight: 900;
}

.hk-medium {
	font-family: "Noto Sans HK", sans-serif;
    font-weight: 500;
}

.hk-bold {
	font-family: "Noto Sans HK", sans-serif;
    font-weight: 700;
}

.hk-heavy {
	font-family: "Noto Sans HK", sans-serif;
    font-weight: 900;
}

.tc-medium {
	font-family: "Noto Sans TC", sans-serif;
    font-weight: 500;
}

.tc-bold{
	font-family: "Noto Sans TC", sans-serif;
    font-weight: 700;
}

.tc-heavy{
	font-family: "Noto Sans TC", sans-serif;
    font-weight: 900;
}

/* SERIF */
.en-serif-medium {
    font-family: "Noto Serif", serif;
    font-weight: 300;   /* Source serif 4 is thicker than other fonts by 200 */
}

.en-serif-bold {
    /* font-family: "Noto Serif", serif;
    font-weight: 500; */
	font-family: "Noto Serif SC", serif;
    font-weight: 700;
}

.en-serif-heavy {
    font-family: "Noto Serif", serif;
    font-weight: 700;
}

.sc-serif-medium {
	font-family: "Noto Serif SC", serif;
    font-weight: 500;
}

.sc-serif-bold {
	font-family: "Noto Serif SC", serif;
    font-weight: 700;
}

.sc-serif-heavy {
	font-family: "Noto Serif SC", serif;
    font-weight: 900;
}

.hk-serif-medium {
	font-family: "Noto Serif HK", serif;
    font-weight: 500;
}

.hk-serif-bold {
	font-family: "Noto Serif HK", serif;
    font-weight: 700;
}

.hk-serif-heavy {
	font-family: "Noto Serif HK", serif;
    font-weight: 900;
}

.tc-serif-medium {
	font-family: "Noto Serif TC", serif;
    font-weight: 500;
}

.tc-serif-bold{
	font-family: "Noto Serif TC", serif;
    font-weight: 700;
}

.tc-serif-heavy{
	font-family: "Noto Serif TC", serif;
    font-weight: 900;
}

/* SEGMENT */
.seven-segment {
    font-family: "SevenSegment Medium";
}