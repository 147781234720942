html {
  scroll-behavior: smooth;
}

/* main container */
.people {
  max-width: 100vw;
}

/* People Scroll Bar */
.barContainer {
  position: relative;
  background-color: black;

  width: 100vw;
  height: 100vh;

  > div {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.barTitle {
  color: white;
  filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.5));

  font-size: 48px;
  height: 70px;
  margin-bottom: 24px;
}

.barInner {
  width: 100%;
  height: 360px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.selectedBio {
  box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.8);
  opacity: 1;
}

/* NOTE: application of NOT selector */
.barInner:hover> :not(.selectedBio) :not(:hover) {
  opacity: 0.5;
}

.BioBlock {
  width: 120px;
  aspect-ratio: 1/3;

  margin: 0 6px 0 6px;

  cursor: pointer;

  position: relative;
}

.BioBlock:hover {
  opacity: 1;
}

.BioBlock>img {
  width: 100%;
  height: 100%;

  display: block;
  object-fit: cover;
}

.BioBlock>p {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;

  color: white;
}

/* Bio Description */
.bioContainer {
  width: 100vw;
  height: 100vh;
  background-color: rgb(49, 142, 224);

  /* font-family: "PingFang Bold"; */

  @media screen and (max-width: 821px) {
    display: block;
  }

  @media screen and (min-width: 822px) {
    display: grid;
    grid-template-rows: 35% 1fr;
    grid-template-columns: 2fr 3fr;
  }
}

.bioPhoto {
  object-fit: cover;

  @media screen and (max-width: 821px) {
    width: 360px;
    height: 480px;
  }

  @media screen and (min-width: 822px) {
    width: 100%;
    height: 100%;
    grid-row: 1 / span 2;
    grid-column: 1;
  }
}

.bioText {
  grid-row: 2;
  grid-column: 2;
  color: white;
}

.bioText>div {
  margin: 20px;
  margin-left: 50px;
  padding: 0px;
}

.bioText span {
  /* background-color: rgb(103, 102, 102); */
}

/* Nav Icon */
.navContainer {
  position: fixed;
  top: 50px;
  right: 80px;

  display: flex;
  flex-direction: row;
}

.left,
.right,
.up {
  width: 50px;
  height: 50px;
  opacity: 0.5;
  cursor: pointer;
}

.left {
  background-color: red;
}

.right {
  background-color: blue;
}

.up {
  background-color: green;
}

/* Typography */